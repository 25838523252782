<script>
import { ref, computed } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import DrawerModal from '/~/components/drawer/components/drawer-modal.vue'

export default {
  name: 'drawer-epurchase-external-modal',
  components: {
    DrawerModal,
    BaseButton,
    BaseAsidePage,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    url: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'giftcard',
      validator: (v) => /giftcard|voucher/.test(v),
    },
  },
  setup(props, { emit }) {
    const loaded = ref(false)
    const iframeRef = ref(null)

    const title = computed(() => {
      switch (props.type) {
        case 'voucher':
          return 'View eVoucher'
        case 'giftcard':
        default:
          return 'View eGift Card'
      }
    })

    function print() {
      iframeRef.value.contentWindow.postMessage('print', '*')
    }

    function hide() {
      emit('update:visible', false)
    }

    return {
      loaded,
      print,
      iframeRef,
      hide,
      title,
    }
  },
}
</script>

<template>
  <drawer-modal
    :to="to"
    :visible="visible"
    :padding="false"
    fullheight
    @hide="hide"
  >
    <base-aside-page :title="title" :back="false" no-padding class="grow">
      <template #action>
        <div class="flex items-center space-x-5">
          <base-button
            icon="plain/printer"
            :disabled="!loaded"
            @click="print"
          />
          <base-button icon="close" @click="hide" />
        </div>
      </template>

      <div class="flex h-full w-full grow flex-col">
        <iframe
          ref="iframeRef"
          :src="url"
          class="w-full grow"
          @load="loaded = true"
        />
      </div>
    </base-aside-page>
  </drawer-modal>
</template>
